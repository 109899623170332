
* {
  font-family: 'Raleway', sans-serif;
}
body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
}
.scPage::-webkit-scrollbar {
  width: 0!important;
}
input, textarea, select, button, Input, Button, Select {
	outline: none!important;
  resize: none!important;
  color: #000!important;
}



.noscbar::-webkit-scrollbar {
  display: none!important;
}
.noscbar {
  scrollbar-width: none!important;
}

.updown {
  animation: updownEffect 2s infinite;
  transition: 300ms ease-in-out;
}
@keyframes updownEffect {
  0% {
    transform: scale(1);
    opacity: .7;
  }
  50% {
    transform: scale(1.2);
    opacity: .9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.zoomIn {
  animation: zoomEff 10s infinite;
  transition: 300ms ease-in-out;
}
@keyframes zoomEff {
  0% {
    background-size: 100%;
  }
  50% {
    background-size: 120%;
  }
  100% {
    background-size: 100%;
  }
}






.chevron-down {
  margin-left: auto;
  transition: transform 0.2s ease-in-out;
}

.szh-accordion {
  &__item {
    border-bottom: 1px solid rgb(202, 202, 205);

    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 2rem 0;
      font-weight: 400;
      text-align: left;
      color: #000;
      font-family: font;
      font-size: 18px;
      background-color: transparent;
      border: none;
    }

    &-content {
      transition: height 0.2s ease-in-out;
    }

    &-panel {
      padding: 0 0 2rem 0;
      color: rgb(100,100,100);
    }

    &--expanded {
      .szh-accordion__item-btn {
        background-color: transparent;
      }
      .chevron-down {
        transform: rotate(180deg);
      }
    }
  }
}